import React from 'react';
import loadable from '@loadable/component';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import BlockContainer from '../PageBuilder/BlockBuilder/BlockContainer';
import Field from '../PageBuilder/Field';
import carouselCSS from '../PageBuilder/SectionBuilder/SectionCarousel/SectionCarousel.module.css';

import FallbackPage from './FallbackPage';

// Import custom components and styles
import css from './ArtistProfile.module.css';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

// Custom Subscribe Form Block Component
const SubscribeFormBlock = props => {
  const { blockId, title } = props;
  
  React.useEffect(() => {
    // Function to check if script already exists
    const scriptExists = () => {
      return document.querySelector(`script[data-uid="7b6e1c09d0"]`);
    };

    // Function to clean up duplicate scripts
    const cleanupDuplicateScripts = () => {
      const scripts = document.querySelectorAll(`script[data-uid="7b6e1c09d0"]`);
      if (scripts.length > 1) {
        // Keep the first script, remove others
        Array.from(scripts).slice(1).forEach(script => script.remove());
      }
    };
    // Function to clean up form modals and backdrops
    const cleanupFormModals = () => {
      const modals = document.querySelectorAll('.formkit-modal');
      const backdrops = document.querySelectorAll('.formkit-overlay');
      
      if (modals.length > 1) {
        // Keep only the last modal
        Array.from(modals).slice(0, -1).forEach(modal => modal.remove());
      }
      
      if (backdrops.length > 1) {
        // Keep only the last backdrop
        Array.from(backdrops).slice(0, -1).forEach(backdrop => backdrop.remove());
      }
    };

    const initializeScript = () => {
      if (!scriptExists()) {
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://joodaic.kit.com/7b6e1c09d0/index.js";
        script.dataset.uid = "7b6e1c09d0";
        document.body.appendChild(script);
      }
    };

    // Initial setup
    if (window.formkit) {
      window.formkit.reload();
      cleanupDuplicateScripts();
      cleanupFormModals();
    } else {
      initializeScript();
    }

    // Set up interval to check for and clean up duplicate modals
    const cleanupInterval = setInterval(cleanupFormModals, 1000);

    // Cleanup function
    return () => {
      clearInterval(cleanupInterval);
      cleanupFormModals();
      const scripts = document.querySelectorAll(`script[data-uid="7b6e1c09d0"]`);
      if (scripts.length === 1) {
        scripts[0].remove();
      }
    };
  }, [blockId]);

  
  return (
    <BlockContainer id={blockId}>
      <div className={css.subscribeButtonContainer}>
        <button 
          className={css.subscribeButton}
          data-formkit-toggle="7b6e1c09d0"
        >
          {title?.content || "Subscribe to Updates"}
        </button>
      </div>
    </BlockContainer>
  );
};

// Custom Artist Profile Block Component
const ArtistProfileBlock = props => {
  const { 
    blockId, 
    media, 
    title, 
    text, 
    responsiveImageSizes, 
    options
  } = props;

  return (
    <BlockContainer id={blockId} className={carouselCSS.block}>
      <div className={css.artistCard}>
        <div className={css.artworkCollage}>
          <Field data={media} sizes={responsiveImageSizes} options={options} />
        </div>
        <div className={css.artistInfo}>
          {text?.content && (
            <div className={css.profileImage}>
              <img src={text.content} alt="" />
            </div>
          )}
          <div className={css.text}>
            <Field data={title} options={options} className={css.artistName} />
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  // Transform the data to use custom block type for featured section
  const transformedData = pageAssetsData?.landingPage?.data
    ? {
        ...pageAssetsData.landingPage.data,
        sections: pageAssetsData.landingPage.data.sections.map(section => {
          // Ensure all sections have the correct type
          const sectionWithType = {
            ...section,
            sectionType: section.sectionType || 'carousel'
          };
          
          // Check if this is the Welcome section
          const isWelcomeSection = 
            section?.sectionName?.toLowerCase() === 'welcome';

          if (isWelcomeSection) {
            return {
              ...sectionWithType,
              sectionType: 'video'
            };
          }

          const isFeaturedSection = 
            section?.title?.content?.toLowerCase().includes('featured') ||
            section?.title?.content?.toLowerCase().includes('artist');

          const isSubscribeSection =
            section?.sectionName?.toLowerCase() === 'subscribe';

          if (isSubscribeSection) {
            return {
              ...sectionWithType,
              blocks: section.blocks.map(block => ({
                ...block,
                blockType: 'subscribeBlock'
              }))
            };
          }

          if (isFeaturedSection) {
            return {
              ...sectionWithType,
              blocks: section.blocks.map(block => ({
                ...block,
                blockType: 'artistBlock'
              }))
            };
          }
          return sectionWithType;
        })
      }
    : null;

  // Define custom components and styles through options
  const options = {
    blockComponents: {
      artistBlock: {
        component: ArtistProfileBlock
      },
      subscribeBlock: {
        component: SubscribeFormBlock
      }
    },
    sectionComponents: {
      video: {
        component: loadable(() =>
          import(/* webpackChunkName: "SectionVideo" */ '../PageBuilder/SectionBuilder/SectionVideo/SectionVideo')
        )
      }
    }
  };

  return (
    <PageBuilder
      pageAssetsData={transformedData}
      inProgress={inProgress}
      error={error}
      options={options}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
